<template>
	<div class="sort-table-wrap">
		<v-data-table
			class="sort-table"
			ref="sortTable"
			:headers="headers"
			:items="items"
			mobile-breakpoint="1"
			disable-sort
			disable-pagination
			hide-default-footer
			:single-expand="false"
			:expanded.sync="expanded"
			:show-expand="expand"
			@click:row="openDetail"
		>
			<template v-slot:header.checked>
				<label
					class="d-flex align-center justify-center"
					@click="onLabelCheckedAllItems"
				>
					<v-simple-checkbox
						:ripple="false"
						:value="isCheckedAllItems"
						@input="onCheckedAllItems"
						:indeterminate="isIndeterminate"
					></v-simple-checkbox>
				</label>
			</template>
			<template
				v-slot:[addItemText(header.value)]="{ item }"
				v-for="header in checkHeaders"
			>
				<slot :name="header.value" :item="item">
					{{ item[header.value] }}
				</slot>
			</template>
			<template v-slot:item.lastUpdateTime="{ item: { lastUpdateTime } }">
				{{ $formatDate(new Date(lastUpdateTime), 'yyyy-MM-dd HH:mm') }}
			</template>
			<template v-slot:item.sort="{ item }">
				<img
					class="sort-icon"
					@mousedown="onSortingMousedown($event, item)"
					@touchstart="onSortingMousedown($event, item)"
					src="../assets/common_btn_sort.png"
					alt=""
				/>
			</template>
			<template v-slot:item.checked="{ item }">
				<v-simple-checkbox
					:ripple="false"
					v-model="item.checked"
					v-if="checkboxFilter(item)"
				/>
			</template>

			<template v-slot:expanded-item="{ item }">
				<td :colspan="headers.length">
					<slot name="expanded-item" :item="item"> </slot>
				</td>
			</template>
		</v-data-table>

		<div id="fixed-table" class="v-data-table" ref="fixedTable">
			<table>
				<tbody>
				<tr id="fixed-tr"></tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'SortCheckboxTable',
	// components: { COMPONENT_NAME },
	props: {
		headers: {
			required: true,
			type: Array,
		},
		items: {
			required: true,
			type: Array,
		},
		httpRoute: {
			type: String,
			required: false,
		},
		httpPath: {
			type: String,
			required: false,
		},
		checkboxFilter: {
			type: Function,
			required: false,
			default: () => true,
		},
		categoryId: {
			type: Number,
			required: false,
		},
		classTypeSorted: {
			type: Boolean,
			required: false,
			default: false,
		},
		expand: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			isCheckedAllItems: false,
			isIndeterminate: false,
			isMobile: false,
			// 拖曳用
			/// MOBILE
			isSorting: false,
			curMobileSortList: null,
			/// PC
			originSortListDOM: null,
			originSortingIndex: 0,
			curSortingIndex: 0,
			mousedownMinusX: 0,
			mousedownMinusY: 0,
			expanded: [],
		}
	},
	computed: {
		checkHeaders() {
			return this.headers.filter(v => v.value !== 'data-table-expand')
		},
	},
	watch: {
		items: {
			deep: true,
			handler(newItems) {
				let checkedLen = 0
				newItems.forEach((e, i) => {
					if (e.checked) {
						checkedLen++
					}
				})
				if (newItems.length === 0) {
					// 若無 items 的話不勾選
				} else if (checkedLen === newItems.length) {
					this.isCheckedAllItems = true
					this.isIndeterminate = false
				} else if (checkedLen > 0) {
					this.isCheckedAllItems = false
					this.isIndeterminate = true
				} else {
					this.isCheckedAllItems = false
					this.isIndeterminate = false
				}
			},
		},
	},
	// created() {},
	mounted() {
		const isMobile = this.$checkMobile()
		this.isMobile = isMobile
		if (!isMobile) {
			window.addEventListener('mousemove', this.onSortMousemove)
			window.addEventListener('mouseup', this.onSortMouseup)
		} else {
			window.addEventListener('touchmove', this.onSortMousemove, {
				passive: false,
			})
			window.addEventListener('touchend', this.onSortMouseup, {
				passive: false,
			})
			// window.addEventListener('keydown', this.onKeyESCCancelSorting)
		}
		document.addEventListener('scroll', this.onTableLazy)
	},
	beforeDestroy() {
		if (!this.isMobile) {
			window.removeEventListener('mousemove', this.onSortMousemove)
			window.removeEventListener('mouseup', this.onSortMouseup)
		} else {
			window.removeEventListener('touchmove', this.onSortMousemove, {
				passive: false,
			})
			window.removeEventListener('touchend', this.onSortMouseup, {
				passive: false,
			})
			// window.removeEventListener('keydown', this.onKeyESCCancelSorting)
		}
		document.removeEventListener('scroll', this.onTableLazy)
	},
	methods: {
		checkAllList() {
			this.items.forEach(e => {
				if (this.checkboxFilter(e)) {
					this.$set(e, 'checked', true)
				}
			})
			this.isCheckedAllItems = true
			this.isIndeterminate = false
		},
		cancelAllList() {
			this.items.forEach(e => {
				if (this.checkboxFilter(e)) {
					this.$set(e, 'checked', false)
				}
			})
			this.isCheckedAllItems = false
			this.isIndeterminate = false
		},
		onCheckedAllItems(checked) {
			checked ? this.checkAllList() : this.cancelAllList()
		},
		onLabelCheckedAllItems() {
			this.isCheckedAllItems ? this.cancelAllList() : this.checkAllList()
		},
		onSortingMousedown(e, item) {
			// 滑鼠XY座標
			const clientX = e.clientX || e?.changedTouches?.[0]?.clientX
			const clientY = e.clientY || e?.changedTouches?.[0]?.clientY

			// 獲取當前設備是否為行動裝置（isMobile）和所有項目（items）
			const { isMobile, items } = this

			// 獲取被拖動項目的 id
			const { id } = item
			let index

			// 根據 item 的 id 找到其在 items 陣列中的索引
			index = items.findIndex(e => e.id === id)

			// 拖曳行的表格
			const fixedTable = this.$refs.fixedTable

			// 拖曳行 (以實際行元素內容填充此拖曳行)
			const fixedTr = fixedTable.querySelector('#fixed-tr')

			// 獲取主要表格中(sortTable)的 tbody 元素
			const tbody = this.$refs.sortTable.$el.querySelector('tbody')

			// 取得所有tr行
			const trs = tbody.querySelectorAll('tr')

			// 根據索引找到目前正在被拖動的 tr 元素
			const curTr = trs[index]

			// 使用 getBoundingClientRect() 獲取該 tr 元素的邊界信息
			const curTrBound = curTr.getBoundingClientRect()
			const cleft = curTrBound.left
			const ctop = curTrBound.top

			// 把正在拖動的 tr 內容設置到固定行（fixedTr）中，讓它顯示在固定表格上
			fixedTr.innerHTML = curTr.innerHTML

			// 添加 "cache" 類別到當前的 tr，標記它為正在拖動的行 (拖曳行的原始元素) = 快取行
			curTr.classList.add('cache')

			// 調整塊取行的透明度和背景顏色
			curTr.style.opacity = 0.6
			curTr.style.backgroundColor = 'rgba(0, 0, 0, 0.1)'
			curTr.style.border = '#FFB5B5'

			// 設置拖曳行中的每個子元素的最小寬度，與快取行的寬度保持一致
			for (let i = 0; i < curTr.children.length; i++) {
				fixedTr.children[i].style.width = curTr.children[i].clientWidth + 'px'
			}

			// 清空拖曳行中的第一個單元格的內容 (通常是拖曳圖示或勾選框)
			fixedTr.children[0].innerHTML = ''

			// 設置拖曳行的高度，與快取行高度保持一致
			fixedTr.style.height = curTr.clientHeight + 'px'

			// 更改拖曳行的樣式
			fixedTable.style.boxShadow = '0 10px 20px 1px rgba(0,0,0,.2)' // 陰影
			fixedTable.style.position = 'fixed'
			fixedTable.style.left = cleft + 'px' // 設置固定表格的左側位置
			fixedTable.style.top = ctop + 'px'  // 設置固定表格的頂部位置
			fixedTable.style.background = '#fff' // 設置背景顏色為白色
			fixedTable.style.zIndex = 999        // 設置 z-index，使其浮動在最上層
			fixedTable.style.display = 'block'   // 顯示固定表格
			fixedTable.style.opacity = 0.9 // 透明度
			fixedTable.style.border = '3px solid #E0E0E0' // 邊框
			fixedTable.classList.add('flicker-border') // 添加邊框呼吸燈效果

			// 組件設置目前拖曳中的狀態 (onSortMousemove用)
			this.isSorting = true

			// 保存初始的 tr 和索引
			this.originSortListDOM = curTr
			this.originSortingIndex = index
			this.curSortingIndex = index

			// 計算滑鼠點擊時，滑鼠相對於 tr 左上角的偏移量
			this.mousedownMinusX = clientX - cleft
			this.mousedownMinusY = clientY - ctop
		},
		onSortMousemove(e) {
			const winScrollY = window.scrollY
			const clientX = e.clientX || e?.changedTouches?.[0]?.clientX
			const clientY = e.clientY || e?.changedTouches?.[0]?.clientY

			// 如果目前正在進行排序（拖曳）
			if (this.isSorting) {
				// 阻止預設事件，避免發生滾動等行為
				e.preventDefault()

				// 拿狀態
				const { originSortListDOM, mousedownMinusX, mousedownMinusY } = this

				// 獲取主要表格跟拖曳表格
				const fixedTable = this.$refs.fixedTable
				const sortTable = this.$refs.sortTable

				// 獲取排序表格的行元素
				const tbody = sortTable.$el.querySelector('.sort-table tbody')
				const trs = tbody.querySelectorAll('tr:not(.cache)')

				// 計算滑鼠與拖曳元素的相對位置
				const compX = clientX - mousedownMinusX
				const compY = clientY - mousedownMinusY

				// 更新拖曳行的位置，讓拖曳行跟隨滑鼠移動
				fixedTable.style.left = compX + 'px'
				fixedTable.style.top = compY + 'px'

				// 第一行元素頂部座標
				const firstTrTop = trs[0].getBoundingClientRect().top

				// 最後一行元素頂部座標
				const lastTrTop = trs[trs.length - 1].getBoundingClientRect().top

				// 滑鼠觸控座標 + 座標相對行元素的Y距離 (修正滑鼠點擊的位置不同時拖曳的判斷基準點) = 判斷基準點
				const datum = clientY - mousedownMinusY

				// 遍歷所有行，根據判斷基準點的 Y 座標來決定操作行應該放置在哪一行的前面或後面
				for (let tri = 0; tri < trs.length; tri++) {
					const tr = trs[tri]

					const hasNext = tri + 1 !== trs.length // 還有下一行可判斷
					const nextTri = hasNext ? tri + 1 : tri // 下一行的索引
					const nextTr = trs[nextTri] // 下一行元素

					// 當前行元素頂部座標
					const trTop = tr.getBoundingClientRect().top

					// 下一行元素頂部座標
					const nextTrTop = nextTr.getBoundingClientRect().top

					// 1.滑鼠位置介於此行跟下行之間
					if (datum > trTop && datum < nextTrTop) {
						// 設置當前排序索引
						this.curSortingIndex = tri + 1
						// 將原來的拖曳行插入到下一行前面
						tbody.insertBefore(originSortListDOM, nextTr)
						break
					} else if (datum < firstTrTop) {
						// 2.如果滑鼠位置在第一行上方，將其移動到第一行
						this.curSortingIndex = 0
						tbody.insertBefore(originSortListDOM, trs[0])
						break
					} else if (datum > lastTrTop) {
						// 3.如果滑鼠位置超過最後一行的下方，將拖曳元素放到最後一行
						this.curSortingIndex = trs.length
						tbody.appendChild(originSortListDOM)
						break
					}
				}
			}
		},
		onSortMouseup(ev) {
			const { isSorting } = this
			if (isSorting) {
				const { originSortingIndex, curSortingIndex } = this
				const fixedTable = this.$refs.fixedTable

				const sortTable = this.$refs.sortTable.$el
				const cacheTr = sortTable.querySelector('tr.cache')

				fixedTable.style.display = 'none'

				cacheTr.style.opacity = 1
				cacheTr.style.backgroundColor = '#fff'
				cacheTr.classList.remove('cache')
				this.isSorting = false

				if (originSortingIndex !== curSortingIndex) {
					this.onSorted(originSortingIndex, curSortingIndex)
				}
			}
		},
		changeSortIds(beginIndex, endIndex, afterSortIds) {
			const afterSortIdsLength = afterSortIds?.length || 0
			for (let index = 0; index < afterSortIdsLength; index++) {
				const afterSortId = afterSortIds[index]
				const isMinus = beginIndex > endIndex
				const minusIndex = isMinus ? afterSortIdsLength - index - 1 : -index
				// this.$set(this.items[Math.abs(beginIndex - minusIndex)], 'sortId', afterSortId)
			}
		},
		async onSorted(firstIndex, lastIndex) {
			// START:: 搓排序 api
			const { classTypeSorted, httpRoute, httpPath, items } = this

			// 自行定義送出api排序方法與網址
			if (classTypeSorted) {
				// 更改物件在陣列中的順序
				// (顯示順序更改後，也要改變實際資料順序，否則畫面與資料陣列順序不同步)
				const globalItem = items.splice(firstIndex, 1)[0]
				items.splice(lastIndex, 0, globalItem)
				this.$emit('onSorted', {
					firstIndex: firstIndex,
					lastIndex: lastIndex,
				})
			} else {
				// api通用排序方法與網址
				try {
					const globalItem = items.splice(firstIndex, 1)[0]
					items.splice(lastIndex, 0, globalItem)
					const sortedIdList = items.map(v => v.id)

					const apiMethod = httpPath ? this.$http[httpRoute][httpPath] : this.$http[httpRoute]
					if (!apiMethod) {
						console.error('API 不存在！')
						return
					}

					await apiMethod.sort({
						...this.$getStoreId(),
						categoryId:
							this.$route.query.categoryId || this.categoryId || undefined,
						sortedIdList: sortedIdList,
					})
					this.changeSortIds(firstIndex, lastIndex, sortedIdList)
					this.$store.commit('showMessage', '排序操作成功')
				} catch (error) {
					$devLog('error', error)
					this.$store.commit('showMessage', {
						text: '排序操作異常',
						color: 'error',
					})
				}
			}

			// END:: 搓排序 api
		},
		onKeyESCCancelSorting({ key }) {
			if (key === 'Escape') {
				this.isSorting = false
			}
		},
		onCancelSorting() {
			this.isSorting = false
		},
		onChangeListIndex(item) {
			const { originSortingIndex, items } = this
			let lastIndex = items.findIndex(e => e.id === item.id)
			const tbody = document.querySelector('.sort-table tbody')
			const trs = tbody.querySelectorAll('tr')
			const originTr = trs[originSortingIndex]
			const changeTr = trs[lastIndex]
			tbody.insertBefore(originTr, changeTr)
			this.onCancelSorting()
			this.onSorted(originSortingIndex, lastIndex)
		},
		addItemText(key) {
			return `item.${key}`
		},
		onTableLazy() {
			const isCall = this.$checkLazy()
			isCall && this.$emit('onTableLazy')
		},
		openDetail(row) {
			if (this.expand) {
				const same = this.expanded?.findIndex(v => v.id === row.id)
				if (same !== -1) {
					this.expanded = this.expanded.filter((item, i) => i !== same)
				} else {
					this.expanded.push(row)
					this.$emit('openDetail', row)
				}
			} else {
				this.$emit('clickRow', row)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.sort-icon {
	cursor: pointer;
	opacity: 0.3;
	height: 24px;
	width: 24px;
	margin: 2px 0 0 0;
}

.sort-ud-icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

::v-deep {
	.v-snack__content {
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;
	}
}

.sort-table {
	&::v-deep {
		.v-data-table__wrapper {
			table {
				.v-simple-checkbox {
					justify-content: center;
				}

				@include rwd(768) {
					.v-simple-checkbox {
						margin-right: 4px !important;
					}
					th,
					td {
						padding: 3px !important;
					}
				}
			}
		}
	}
}

// 拖曳元素的動畫效果
@keyframes borderFlash {
	0% {
		border-color: #C4E1FF;
	}
	50% {
		border-color: #2894FF; /* 這裡設定為紅色，可以根據需要改變顏色 */
	}
	100% {
		border-color: #C4E1FF;
	}
}

.flicker-border {
	border: 2px solid transparent;
	animation: borderFlash 1.6s infinite;
}
</style>